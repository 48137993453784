import { jsx, Container } from "theme-ui"
import { animated, useSpring, config } from "react-spring"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import _get from "lodash/get"

import React from 'react'

const InstagramPosts = () => {
    const data = useStaticQuery(graphql`
    query InstagramQuery {
        allInstaNode(sort: { fields: [timestamp], order: DESC}) {
            edges {
              node {
                mediaType
                permalink
                preview
                original
                timestamp
                caption
                hashtags
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500)
                  }
                },
                
              }
            }
          }

    }
    `)
    let arrayOfInstaImages = _get(data, 'allInstaNode.edges')

    return (
        <div>
            <div style={{ maxWidth: `1500px`, marginLeft: `auto`, marginRight: `auto` }}>
                <div style={{ textAlign: 'center' }}>
                    <h1 >Instagram Feed</h1>
                </div>
                {/* <div style={{ maxWidth: `900px`, marginBottom: `1.45rem`, display: 'flex' }}> */}
                <Container sx={{
                    mt: [`2rem`, `2rem`, `3rem`],
                    display: `grid`,
                    gridTemplateColumns: [`1fr`, `repeat(auto-fill, minmax(350px, 1fr))`],
                    gridGap: 4,
                    alignItems: `flex-start`,
                }}>

                    {arrayOfInstaImages.map((item) => {
                        const posts = getImage(item.node.localFile.childImageSharp.gatsbyImageData)
                        return (
                            <div style={{ marginLeft: `auto`, marginRight: `auto` }}>

                                <a href={item.node.permalink}>
                                    <GatsbyImage
                                        // sx={{

                                        //     mb: [2, 2, 3],
                                        //     boxShadow: `xl`,

                                        // }}
                                        image={posts}
                                        alt={item.mediaType}

                                    />
                                </a>

                            </div>

                        )
                    })}

                </Container>
            </div>
        </div >

    )
}

export default InstagramPosts
