import React from 'react'
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image"
import { jsx, Container } from "theme-ui"
import _get from "lodash/get"
import { filter } from 'lodash'




const TeamCrew = () => {
    const TeamData = useStaticQuery(graphql`
    query TeamQuery{
        allMdx(filter: {slug: {regex: "/team/"}}, sort: {fields: frontmatter___weight}) {
            edges {
              node {
                slug
                frontmatter {
                  image
                  instaurl
                  phoneno
                  title
                }
              }
            }
          }
          
        }`)
    let arrayOfTeamImages = _get(TeamData, 'allMdx.edges')

    return (
        <div>
            <div style={{ maxWidth: `900px`, marginLeft: `auto`, marginRight: `auto` }}>
                <div style={{ textAlign: 'center' }}>
                    <h1>Team Crew</h1>
                </div>
                <Container sx={{
                    mt: [`1rem`, `1rem`, `1rem`],
                    display: `grid`,
                    gridTemplateColumns: [`1fr`, `repeat(auto-fill, minmax(200px, 1fr))`],
                    gridGap: 50,
                    marginLeft: `auto`,
                    marginRight: `auto`
                }}>

                    {arrayOfTeamImages.map((item) => {
                        return (
                            <div style={{ textAlign: `center` }}>

                                <img style={{
                                    maxWidth: 250,
                                    borderRadius: 30,
                                    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    filter: "grayscale(100%)"
                                }}
                                    src={withPrefix(item.node.frontmatter.image)} alt={item.node.frontmatter.title} />
                                <p style={{
                                    margin: `0.6rem`
                                }}>
                                    <b>
                                        {item.node.frontmatter.title}
                                    </b>
                                </p>
                                <a style={{
                                    margin: `0.6rem`
                                }} href={item.node.frontmatter.instaurl}>
                                    <img style={{ maxWidth: 30 }}
                                        src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg" />
                                </a>

                            </div>
                        )
                    })}

                </Container>

            </div>
        </div>

    )
}

export default TeamCrew
